import './App.css';
import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './pages/Home';
import Error404 from './features/banners/Error404';
import LegalPage from "../src/features/legal/LegalPage"
import JobDashboardPage from "../src/pages/JobDashboardPage"
import Login from "../src/features/auth/Login"
import Profile from "../src/features/auth/Profile"
import ForgotPassword from "../src/features/auth/ForgotPassword"
import ResetPassword from "../src/features/auth/ResetPassword"
import { NotificationProvider } from './NotificationContext';
import Notification from '../src/features/banners/Notification';
import AppliedJobs from './features/jobs/AppliedJobs';
import JobDetailsPage from "./pages/JobDetailsPage";
import AdminLogin from './features/admin/AdminLogin';
import AdminDashboard from './features/admin/AdminDashboard';
import UserDetails from './features/admin/UserDetails';
import AdminProtectedRoute from './features/admin/AdminProtectedRoute';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "*",
    element: <Error404 />,
  },
  {
    path: "/legal",
    element: <LegalPage />,
  },
  {
    path: "/jobs",
    element: <JobDashboardPage/>,
  },
  {
    path: "/login",
    element: <Login/>,
  },
  {
    path: "/profile",
    element: <Profile/>,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:token",
    element: <ResetPassword />,
  },
  {
    path: "/applied-jobs",
    element: <AppliedJobs />,
  },
  
  {
    path: "/job-details/:jobId",
    element: <JobDetailsPage />,
  },
  {
    path: "/admin/login",
    element: <AdminLogin />,
  },
  {
    path: "/admin/dashboard",
    element: <AdminProtectedRoute><AdminDashboard /></AdminProtectedRoute>,
  },
  {
    path: "/admin/users/:userId",
    element: <AdminProtectedRoute><UserDetails /></AdminProtectedRoute>,
  },
]);

function App() {
  return (
    <NotificationProvider>
      <div className="App">
        <Notification />
        <RouterProvider router={router}/>
      </div>
    </NotificationProvider>
  );
}

export default App;