import Navbar from "../navbar/Navbar"
import landingPageImage from "../../images/landingimage.png"

export default function HeroSection() {
  return (
    <>
      <div className="bg-white">
        <div className="pb-20">
        <Navbar />
        </div>
        <main>
          <section className="pt-10 bg-white">
            <div className="px-12 mx-auto max-w-7xl">
              <div className="w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center">
                <h1 className="mb-8 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-6xl md:tracking-tight">
                  <span>Search</span> <span className="block w-full py-2 text-transparent bg-clip-text leading-12 bg-gradient-to-r from-green-400 to-indigo-500 lg:inline">Visa Sponsored Jobs</span> <span>like never before 🚀</span>
                </h1>
                <p className="px-0 mb-8 text-lg text-gray-600 md:text-xl lg:px-24">
                  Start your job search with us and find the best visa sponsored jobs with more than 1,17,000+ comapnies across 70+ industries with visa sponsorship license.
                </p>
                <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                  <a href="/jobs" className="inline-flex items-center justify-center text-white bg-gradient-to-br from-indigo-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm w-full sm:w-80  py-2.5 text-center me-2 mb-2">
                    View Jobs
                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  </a>
                </div>
              </div>
              <div className="w-full mx-auto sm:-mt-10 flex justify-center sm:w-80">
                <div className="relative z-0">
                  <div className="relative overflow-hidden">
                    <img src={landingPageImage} alt="Dashboard" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  )
}
