import React, { useState, useEffect,useCallback } from "react";
import Navbar from "../navbar/Navbar";
import { useNotification } from "../../NotificationContext"; // Import this
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';


function JobDashboard() {
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [expandedJobId, setExpandedJobId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { showNotification } = useNotification();
  const [userName, setUserName] = useState("");
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [buttonVisible, setButtonVisible] = useState("hidden");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setIsLoggedIn(false);
          return;
        }

        const response = await api.get(
          "/auth/profile",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.subscriptionStatus === "active") {
          setIsSubscribed(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Handle unauthorized access
          setIsLoggedIn(false);
          showNotification(
            "Please log in to check your subscription status",
            "error"
          );
          // Optionally remove invalid token
          localStorage.removeItem("token");
        } else {
          console.error("Error fetching subscription status:", error);
          showNotification("Error fetching subscription status", "error");
        }
      }
    };

    fetchSubscriptionStatus();
  }, [showNotification]);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const fetchJobs = useCallback(async () => {
    if (!searchQuery) return;
  
    try {
      setIsLoading(true);
      const response = await api.get("/jobs", {
        params: {
          query: searchQuery,
          page: currentPage,
          num_pages: 1,
        },
      });
  
      const newJobs = response.data.data;
      setJobs(newJobs);
      setTotalPages(response.data.total_pages);
      
      // Store the jobs and search query in local storage
      localStorage.setItem('cachedJobs', JSON.stringify(newJobs));
      localStorage.setItem('cachedSearchQuery', searchQuery);
      localStorage.setItem('cachedCurrentPage', currentPage);
      localStorage.setItem('cachedTotalPages', response.data.total_pages);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setIsLoading(false);
    }
  }, [searchQuery, currentPage]);


  useEffect(() => {
    const fetchAppliedJobs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;
  
        const response = await api.get(
          "/user/applied-jobs",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAppliedJobs(response.data.appliedJobs.map((job) => job.job_id));
      } catch (error) {
        console.error("Error fetching applied jobs:", error);
      }
    };
  
    if (isLoggedIn) {
      fetchAppliedJobs();
    }
  }, [isLoggedIn]);

  
  const handleViewDetails = (jobId) => {
    navigate(`/job-details/${jobId}`);
  };



  const handleApply = async (job_id, job_apply_link) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        showNotification("Please log in to apply for jobs", "error");
        return;
      }
  
      await api.post(
        "/user/apply-job",
        { job_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      setAppliedJobs((prevAppliedJobs) => [...prevAppliedJobs, job_id]);
      showNotification("Job application successful", "success");
  
      // Open job details in a new tab
      window.open(`/job-details/${job_id}`, '_blank');
    } catch (error) {
      console.error("Error applying for job:", error);
      const errorMessage =
        error.response?.data?.error || "Error applying for job";
      showNotification(errorMessage, "error");
    }
  };

  useEffect(() => {
    const cachedJobs = localStorage.getItem('cachedJobs');
    const cachedSearchQuery = localStorage.getItem('cachedSearchQuery');
    const cachedCurrentPage = localStorage.getItem('cachedCurrentPage');
    const cachedTotalPages = localStorage.getItem('cachedTotalPages');
  
    if (cachedJobs && cachedSearchQuery) {
      setJobs(JSON.parse(cachedJobs));
      // Only set the search query if it's currently empty
      if (!searchQuery) {
        setSearchQuery(cachedSearchQuery);
      }
      setCurrentPage(parseInt(cachedCurrentPage, 10) || 1);
      setTotalPages(parseInt(cachedTotalPages, 10) || 1);
      setIsLoading(false);
    }
  }, []);  // Empty dependency array, this effect runs only once when component mounts

  useEffect(() => {
    // Check if user is logged in
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    setIsLoggedIn(!!token);
    if (user && user.name) {
      setUserName(capitalizeFirstLetter(user.name));
    }
  }, []);

  
  
  const handleSearch = useCallback(() => {
    if (!searchQuery.trim()) {
      showNotification("Please enter a search query", "error");
      return;
    }
    setCurrentPage(1);
    localStorage.setItem('cachedSearchQuery', searchQuery);
    localStorage.removeItem('cachedJobs');
    localStorage.removeItem('cachedCurrentPage');
    localStorage.removeItem('cachedTotalPages');
    fetchJobs();
  }, [searchQuery, fetchJobs, showNotification]);

  
  
  const handlePageChange = useCallback((pageNumber) => {
    if (!isLoggedIn) {
      showNotification("Please login or register to view more jobs", "error");
      return;
    }
    setCurrentPage(pageNumber);
    localStorage.setItem('cachedCurrentPage', pageNumber);
    window.scrollTo(0, 0);
    fetchJobs();
  }, [isLoggedIn, fetchJobs, showNotification]);

  const truncateDescription = (description, wordLimit) => {
    const words = description.split(" ");
    if (words.length <= wordLimit) {
      return description;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  const toggleDescription = (jobId) => {
    setExpandedJobId((prevJobId) => (prevJobId === jobId ? null : jobId));
  };

  return (
    <>
      <div className='pb-20'>
        <Navbar />
      </div>
      <div className="pt-20">
        <div className="text-2xl font-bold text-center sm:text-5xl">
          Welcome to your job board{" "}
          <span className="text-3xl sm:text-5xl p-4 text-center font-bold from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-r bg-clip-text text-transparent">
            {isLoggedIn ? userName : "Unregistered User"}
          </span>
        </div>
        <div className="flex outline-none rounded-lg mx-5 mt-10 max-w-md sm:mx-auto">
        <input
  type="text"
  placeholder="For example: Marketing Executive"
  className="w-full outline-none bg-white text-gray-600 text-sm px-4 py-3"
  value={searchQuery}
  onChange={(e) => {
    const newValue = e.target.value;
    setSearchQuery(newValue);
    if (newValue === '') {
      // Clear the cached search query when the input is completely empty
      localStorage.removeItem('cachedSearchQuery');
    }
  }}
/>
          <button
            type="button"
            className="flex items-center justify-center bg-[#000000] px-5 text-sm text-white"
            onClick={handleSearch}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Search"}
          </button>
        </div>
        {isLoading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 mb-4"></div>
            <p className="text-gray-600 animate-pulse font-bold">
              Loading your Visa Sponsored Jobs
            </p>
          </div>
        ) : jobs.length === 0 ? (
          <div className="flex flex-col justify-center items-center mt-10">
            <p className="text-gray-600 mx-4 font-semibold">Just enter your desired job role in the search bar above and see the magic ....🚀</p>
            <a className="pt-5 underline text-indigo-600" href="/applied-jobs">View Previously Applied Jobs</a>
          </div>
        ) : (
          <div>
            {jobs.map((job) => (
              <div
                key={job.job_id}
                className="group mx-2 mt-10 grid max-w-screen-md grid-cols-12 space-x-8 overflow-hidden rounded-lg border py-8 text-gray-700 shadow transition hover:shadow-lg sm:mx-auto"
              >
                
                 <a href={job.employer_website}
                  className="order-2 col-span-1 mt-4 -ml-14 text-left text-gray-600 hover:text-gray-700 sm:-order-1 sm:ml-4"
                >
                  <div className="group relative h-16 w-16 overflow-hidden rounded-lg">
                    <img
                      src={job.employer_logo}
                      alt={job.employer_name}
                      className="h-full w-full object-cover text-gray-700"
                    />
                  </div>
                </a>
                <div className="col-span-11 flex flex-col pr-8 text-left sm:pl-4">
                  <h3 className="text-sm text-gray-600">{job.employer_name}</h3>
                  
                  <a  href={job.job_apply_link}
                    className="mb-3 overflow-hidden pr-7 text-lg font-semibold sm:text-xl"
                  >
                    {job.job_title}
                  </a>
                  <p className="overflow-hidden pr-7 text-sm">
                    {expandedJobId === job.job_id
                      ? job.job_description
                      : truncateDescription(job.job_description, 50)}
                  </p>
                  {job.job_description.split(" ").length > 50 && (
                    <button
                      className="mt-2 text-sm text-blue-500 hover:underline"
                      onClick={() => toggleDescription(job.job_id)}
                    >
                      {expandedJobId === job.job_id ? "Less" : "More"}
                    </button>
                  )}
                  <div className="mt-5 flex flex-col space-y-3 text-sm font-medium text-gray-500 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-2">
                    <div>
                      Last Date to Apply:
                      <span className="ml-2 mr-3 rounded-full bg-green-100 px-2 py-0.5 text-green-900">
                        {job.job_offer_expiration_datetime_utc
                          ? new Date(
                              job.job_offer_expiration_datetime_utc
                            ).toLocaleDateString()
                          : "N/A"}
                      </span>
                    </div>
                    <div>
                      Sponsorship Available:
                      <span className="ml-2 mr-3 rounded-full bg-green-100 px-2 py-0.5 text-green-900">
                        {job.sponsorshipAvailable}
                      </span>
                    </div>
                    <div className="flex justify-center ml-8">
  {isLoggedIn ? (
    appliedJobs.includes(job.job_id) ? (
      <button
      onClick={() => handleViewDetails(job.job_id)}
      className="text-white bg-gray-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-2"
      >
        Already Applied
      </button>
    ) : (
      <button
      onClick={() => handleApply(job.job_id)}
      className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-2"
      >
        View Details
      </button>
    )
  ) : (
    <button
      onClick={() => showNotification("Please log in to apply for jobs", "info")}
      className="text-white bg-gray-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-2"
    >
      View Details
    </button>
  )}
</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="flex flex-col items-center mt-20">
  <span className="text-sm text-gray-700 dark:text-gray-400">
    Page{" "}
    <span className="font-semibold text-gray-900 dark:text-white">
      {currentPage}
    </span>
  </span>
  <div className="inline-flex mt-2 xs:mt-0">
    {isLoggedIn ? (
      isSubscribed ? (
        <>
          <button
            className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1 || isLoading}
          >
            <svg
              className="w-3.5 h-3.5 me-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 5H1m0 0 4 4M1 5l4-4"
              />
            </svg>
            {isLoading ? "Loading..." : "Prev"}
          </button>
          <button
            className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages || isLoading}
          >
            {isLoading ? "Loading..." : "Next"}
            <svg
              className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
        </>
      ) : (
        <div className="flex flex-col items-center">
          <div className="filter blur-sm pointer-events-none flex">
            <button className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s">
              Prev
            </button>
            <button className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e">
              Next
            </button>
          </div>
          
           <a className="mt-2 text-sm font-black text-gray-600"
            href="/subscribe"
          >
            Please subscribe to see all the results.
          </a>
        </div>
      )
    ) : (
      <div className="flex flex-col items-center">
        <div className="filter blur-sm pointer-events-none flex">
          <button className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s">
            Prev
          </button>
          <button className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e">
            Next
          </button>
        </div>
        
         <a className="mt-2 text-sm font-black text-gray-600"
          href="/login"
        >
          Please login or Request Access to view more jobs.
        </a>
      </div>
    )}
  </div>
</div>
    </>
  );
}

export default JobDashboard;
