import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../features/navbar/Navbar';
import Footer from '../features/footer/Footer';
import { useNotification } from "../NotificationContext";
import api from '../utils/api';

const JobDetailsPage = () => {
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const [isGeneratingDocuments, setIsGeneratingDocuments] = useState(false);
  const [isRegeneratingResume, setIsRegeneratingResume] = useState(false);
  const [isRegeneratingCoverLetter, setIsRegeneratingCoverLetter] = useState(false);
  const [isDownloadingCoverLetter, setIsDownloadingCoverLetter] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');
  const { jobId } = useParams();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [coverLetterContent, setCoverLetterContent] = useState('');
  const [hasResume, setHasResume] = useState(false);


  const careerMessages = [
    "Crafting your professional story...",
    "Highlighting your unique skills...",
    "Tailoring your experience to the role...",
    "Polishing your career achievements...",
    "Preparing to showcase your potential..."
  ];

  const fetchJobDetails = useCallback(async () => {
    try {
      const response = await api.get(`/jobs/${jobId}`);
      setJob(response.data);
    } catch (error) {
      setError('Failed to load job details. Please try again later.');
      showNotification('Failed to load job details. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  }, [jobId, showNotification]);

  useEffect(() => {
    fetchJobDetails();
  }, [fetchJobDetails]);
  

  const generateDocuments = async () => {
    setIsGeneratingDocuments(true);
    setError(null);
    setLoadingProgress(0);
    
    const updateProgress = () => {
      setLoadingProgress(prev => {
        const newProgress = prev + Math.random() * 15;
        return newProgress > 90 ? 90 : newProgress;
      });
      setLoadingMessage(careerMessages[Math.floor(Math.random() * careerMessages.length)]);
    };

    const progressInterval = setInterval(updateProgress, 2500);

    try {
      const token = localStorage.getItem('token');
      const resumeResponse = await api.post(`/jobs/${jobId}/apply`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const coverLetterResponse = await api.post(`/jobs/${jobId}/generate-cover-letter`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setApplicationData(resumeResponse.data);
      setCoverLetterContent(coverLetterResponse.data.content);
      showNotification('Documents generated successfully!', 'success');
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'An error occurred while generating documents';
      setError(errorMessage);
      showNotification(errorMessage, 'error');
    } finally {
      clearInterval(progressInterval);
      setLoadingProgress(100);
      setIsGeneratingDocuments(false);
    }
  };

  const checkUserResume = useCallback(async () => {
    try {
      const response = await api.get(`/user/has-resume`);
      setHasResume(response.data.hasResume);
    } catch (error) {
      console.error('Error checking user resume:', error);
      setHasResume(false);
    }
  }, []);
  
  useEffect(() => {
    fetchJobDetails();
    checkUserResume();
  }, [fetchJobDetails, checkUserResume]);

  const downloadCoverLetter = async () => {
    setIsDownloadingCoverLetter(true);
    try {
      const token = localStorage.getItem('token');
      const response = await api.get(`/jobs/${jobId}/cover-letter`, {
        responseType: 'arraybuffer',
        headers: { 
          Authorization: `Bearer ${token}`,
          'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        }
      });
      
      const blob = new Blob([response.data], { 
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cover_letter_${jobId}.docx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      showNotification('Cover letter downloaded successfully!', 'success');
    } catch (error) {
      showNotification('Failed to download cover letter. Please try again.', 'error');
    } finally {
      setIsDownloadingCoverLetter(false);
    }
  };

  const regenerateDocuments = async () => {
    setIsGeneratingDocuments(true);
    setError(null);
    setLoadingProgress(0);
    
    const updateProgress = () => {
      setLoadingProgress(prev => {
        const newProgress = prev + Math.random() * 15;
        return newProgress > 90 ? 90 : newProgress;
      });
      setLoadingMessage(careerMessages[Math.floor(Math.random() * careerMessages.length)]);
    };
  
    const progressInterval = setInterval(updateProgress, 2500);
  
    try {
      const token = localStorage.getItem('token');
      const resumeResponse = await api.post(`/jobs/${jobId}/apply`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const coverLetterResponse = await api.post(`/jobs/${jobId}/generate-cover-letter`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      setApplicationData(resumeResponse.data);
      setCoverLetterContent(coverLetterResponse.data.content);
      showNotification('Documents regenerated successfully!', 'success');
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'An error occurred while regenerating documents';
      setError(errorMessage);
      showNotification(errorMessage, 'error');
    } finally {
      clearInterval(progressInterval);
      setLoadingProgress(100);
      setIsGeneratingDocuments(false);
    }
  };

  const regenerateResume = async () => {
    setIsRegeneratingResume(true);
    try {
      const token = localStorage.getItem('token');
      const response = await api.post(`/jobs/${jobId}/apply`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setApplicationData(response.data);
      showNotification('Resume regenerated successfully!', 'success');
    } catch (error) {
      showNotification('Failed to regenerate resume. Please try again.', 'error');
    } finally {
      setIsRegeneratingResume(false);
    }
  };

  const regenerateCoverLetter = async () => {
    setIsRegeneratingCoverLetter(true);
    try {
      const token = localStorage.getItem('token');
      const response = await api.post(`/jobs/${jobId}/generate-cover-letter`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      // Update the cover letter content with the new generated content
      setCoverLetterContent(response.data.content);
      
      showNotification('Cover letter regenerated successfully!', 'success');
    } catch (error) {
      showNotification('Failed to regenerate cover letter. Please try again.', 'error');
    } finally {
      setIsRegeneratingCoverLetter(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-red-500 text-xl mb-4">{error}</p>
        <button 
          onClick={() => navigate('/jobs')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          Back to Jobs
        </button>
      </div>
    );
  }

  if (!job) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-xl mb-4">Job not found.</p>
        <button 
          onClick={() => navigate('/jobs')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          Back to Jobs
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <div className='pb-20'>
        <Navbar />
      </div>
      <main className="flex-grow container mx-auto pb-4 pt-10 py-8">
        <div className="bg-white rounded-lg overflow-hidden">
          <div className="p-6">
            <h1 className="text-3xl font-bold mb-4">{job.job_title}</h1>
            <div className="flex flex-wrap justify-between items-start">
              <div>
                <p className="text-xl font-bold">{job.employer_name}</p>
                <p className="text-gray-600">Location: {job.job_city || 'N/A'}</p>
                <p className="mt-2">{job.job_employment_type}</p>
                <p className="mt-2">Posted: {new Date(job.job_posted_at_datetime_utc).toLocaleDateString()}</p>
              </div>
              {job.employer_logo && (
                <img src={job.employer_logo} alt={job.employer_name} className="w-32 h-32 object-contain" />
              )}
            </div>
          </div>
          
          <div className="border-t border-gray-200 p-6">
            <h2 className="text-2xl font-semibold mb-4">Job Description</h2>
            <p className="whitespace-pre-wrap">{job.job_description}</p>
          </div>

          {job.job_highlights && Object.keys(job.job_highlights).length > 0 && (
            <div className="border-t border-gray-200 p-6">
              {Object.entries(job.job_highlights).map(([key, values]) => (
                <div key={key} className="mb-6">
                  <h2 className="text-2xl font-semibold mb-2">{key}</h2>
                  <ul className="list-disc pl-5">
                    {values.map((item, index) => (
                      <li key={index} className="mb-1">{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}

          {job.apply_options && job.apply_options.length > 0 && (
            <div className="border-t border-gray-200 p-6">
              <h2 className="text-2xl font-semibold mb-4">Apply Links</h2>
              <ul className="list-disc pl-5">
                {job.apply_options.map((option, index) => (
                  <li key={index} className="mb-2">
                    <a 
                      href={option.apply_link} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 underline"
                    >
                      Apply on {option.publisher}
                    </a>
                    {option.is_direct && " (Direct application)"}
                  </li>
                ))}
              </ul>
            </div>
          )}

<div className="border-t border-gray-200 p-6">
  {!applicationData && (
    hasResume ? (
      <button 
        onClick={generateDocuments}
        disabled={isGeneratingDocuments}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg w-full sm:w-auto transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mb-4"
      >
        {isGeneratingDocuments ? (
          <span className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Generating Documents...
          </span>
        ) : 'Generate Documents'}
      </button>
    ) : (
      <button 
        onClick={() => navigate('/profile')}
        className="bg-gray-500 text-white font-bold py-3 px-6 rounded-lg w-full sm:w-auto transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mb-4"
      >
        Please go to your profile first and upload a resume
      </button>
    )
  )}  
  {isGeneratingDocuments && (
    <div className="mt-6 w-full max-w-md mx-auto">
      <div className="mb-2 text-sm font-medium text-gray-700">{loadingMessage}</div>
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 overflow-hidden">
        <div 
          className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-out"
          style={{ width: `${loadingProgress}%` }}
        ></div>
      </div>
      <div className="mt-2 text-xs text-gray-500 text-right">{Math.round(loadingProgress)}%</div>
    </div>
  )}
</div>

          {applicationData && (
            <div className="border-t border-gray-200 p-6">
              
              <div className="flex ">
                {applicationData && (
      <div className="border-t border-gray-200 p-6">
        <h2 className="text-2xl sm:text-4xl font-bold mb-8 text-center underline">Generated Documents</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <h3 className="text-xl font-semibold mb-2 text-center">Resume</h3>
            {applicationData.png_images.map((image, index) => (
              <img key={index} src={image} alt={`Resume page ${index + 1}`} className="w-full h-auto object-contain rounded-lg shadow-md mb-4" />
            ))}
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2 text-center">Cover Letter</h3>
            <div className="bg-gray-100 p-4 rounded-lg shadow-md -mx-8 sm:mx-0">
              <pre className="whitespace-pre-wrap">{coverLetterContent}</pre>
            </div>
          </div>
        </div>
        <div className="mt-6 flex flex-wrap gap-4">
          {/* ... (download buttons remain the same) */}
        </div>
      </div>
    )}
              </div>
              <div className="mt-6 flex flex-wrap justify-center gap-4">
                <a 
                  href={applicationData.pdf_file} 
                  download 
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                >
                  Download Resume PDF
                </a>
                <button 
                  onClick={downloadCoverLetter}
                  disabled={isDownloadingCoverLetter}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                >
                  {isDownloadingCoverLetter ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Downloading...
                    </span>
                  ) : 'Download Cover Letter'}
                </button>
                <button 
                  onClick={regenerateDocuments}
                  disabled={isGeneratingDocuments}
                  className="bg-red-700 hover:bg-red-900 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                >
                  {isGeneratingDocuments ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Regenerating...
                    </span>
                  ) : 'Regenerate Documents'}
                </button>
                <button 
                  onClick={regenerateResume}
                  disabled={isRegeneratingResume}
                  className="bg-red-700 hover:bg-red-900 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                >
                  {isRegeneratingResume ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Regenerating Resume...
                    </span>
                  ) : 'Regenerate Resume'}
                </button>
                <button 
                  onClick={regenerateCoverLetter}
                  disabled={isRegeneratingCoverLetter}
                  className="bg-red-700 hover:bg-red-900 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                >
                  {isRegeneratingCoverLetter ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Regenerating Cover Letter...
                    </span>
                  ) : 'Regenerate Cover Letter'}
                </button>
              </div>
            </div>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default JobDetailsPage;