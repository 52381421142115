import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';

const LegalPage = () => {
  return (
    <>
    <Navbar />
    <div className="bg-gray-100 min-h-screen">
      <header className="bg-blue-600 text-white py-4">
        <div className="container mx-auto px-4">
          <h1 className="text-2xl font-bold">UKworkx - Terms and Conditions</h1>
        </div>
      </header>

      <main className="container mx-auto pt-8">
        <div className="bg-white shadow-md rounded-lg p-6">
          <section>
            <h2 className="text-xl font-bold mb-4">1. Introduction</h2>
            <p>Introduction
1.1. Welcome to UKworkx, a career guidance and job search support service. These Terms and Conditions ("Terms") govern your use of the UKworkx website (www.ukworkx.com) and the services provided by UKworkx ("we", "us", "our"). By accessing or using our website and services, you agree to be bound by these Terms in their entirety. If you do not agree with any part of these Terms, you must not use our website or services.
1.2. These Terms constitute a legally binding agreement between you and UKworkx. It is important that you read and understand these Terms before using our website or services. If you have any questions or concerns regarding these Terms, please contact us at [help@ukworkx.com] prior to using our website or services.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">2. Services</h2>
            <p>Services
2.1. UKworkx offers a range of career guidance and job search support plans, as described on our pricing page. Each plan includes specific features and benefits designed to assist you in your job search and career development.
2.2. The features and duration of each plan are subject to change at our discretion. We reserve the right to modify, suspend, or discontinue any aspect of our services at any time without prior notice. In the event of a material change to your current plan, we will make reasonable efforts to notify you in advance.
2.3. Our plans are offered on a one-time payment basis. The term "one-time payment" indicates that you will be charged a single, upfront fee for the selected plan. This payment grants you access to the plan's features and benefits for a limited time, as specified on our pricing page.
2.4. The maximum duration of any plan is 3 months or until you find a job, whichever occurs earlier. After this period, your access to the plan's features and benefits will expire, unless you purchase a new plan.
2.5. UKworkx does not guarantee job placement as a result of using our services. While we strive to provide valuable career guidance and job search support, the success of your job search ultimately depends on various factors, including your qualifications, experience, and market conditions.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">3. Payment and Refunds</h2>
            <p>Payment and Refunds
3.1. All payments made to UKworkx for our services are non-refundable. By purchasing a plan, you acknowledge and agree that you will not be entitled to a refund, regardless of your satisfaction with the services provided.
3.2. We do not offer money-back guarantees for our services. It is your responsibility to carefully review the features and benefits of each plan before making a purchase decision.
3.3. Payments are processed through secure third-party payment gateways. UKworkx does not store or have access to your complete payment information. By making a payment, you agree to the terms and conditions of the respective payment gateway provider.
3.4. In the event of a payment dispute or chargeback, UKworkx reserves the right to suspend or terminate your access to our services immediately, without prior notice.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">4. User Responsibilities</h2>
            <p>User Responsibilities
4.1. By uploading your resume to our website, you grant UKworkx a non-exclusive, royalty-free, perpetual, and irrevocable license to store your resume on our servers and use it to train our machine learning algorithms for the purpose of improving our services for other users.
4.2. You represent and warrant that you have the necessary rights and permissions to upload and share your resume with UKworkx. You must not upload any resume or other content that infringes upon the intellectual property rights of others or violates any applicable laws or regulations.
4.3. You are solely responsible for the content and accuracy of your resume and any other information you provide to us. UKworkx does not verify the accuracy or completeness of user-provided information and assumes no liability for any errors, omissions, or misrepresentations.
4.4. You agree to use our website and services only for lawful purposes and in compliance with these Terms and all applicable laws and regulations. You must not use our website or services to engage in any fraudulent, abusive, or discriminatory activities.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">5. Free Resume Analyzer and ATS Tool</h2>
            <p>Free Resume Analyzer and ATS Tool
5.1. UKworkx provides a free resume analyzer and applicant tracking system (ATS) tool for users. These tools are intended to help you assess and optimize your resume for job applications.
5.2. The free resume analyzer and ATS tool are provided on an "as-is" basis, without any guarantees or warranties of any kind, whether express or implied. UKworkx does not guarantee the accuracy, reliability, or effectiveness of these tools in improving your job search outcomes.
5.3. Users are solely responsible for the use of the free resume analyzer and ATS tool. UKworkx shall not be liable for any damages or losses resulting from the use of these tools, including but not limited to errors in analysis, missed job opportunities, or any other consequences.
5.4. UKworkx reserves the right to modify, suspend, or discontinue the free resume analyzer and ATS tool at any time without prior notice. We may also impose limits on the use of these tools, such as the number of resumes that can be analyzed or the frequency of use.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">6. Data Privacy</h2>
            <p>Data Privacy
6.1. UKworkx is committed to protecting your privacy and ensuring the security of your personal information. By uploading your resume and using our services, you consent to UKworkx collecting, storing, and processing your personal information in accordance with our Privacy Policy.
6.2. We collect and use your personal information only for the purposes described in our Privacy Policy, such as providing and improving our services, responding to your inquiries, and communicating with you about our services.
6.3. UKworkx will not share your personal data with third parties without your explicit consent, except as required by law or as described in our Privacy Policy. We may disclose your personal information to comply with legal obligations, enforce our Terms and Conditions, or protect the rights, property, or safety of UKworkx, our users, or others.
6.4. We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
6.5. You have the right to access, correct, or delete your personal information that we hold. You may request this by contacting us at the email address provided in our Privacy Policy.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">7. Limitation of Liability</h2>
            Limitation of Liability
7.1. UKworkx shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or relating to your use of our website or services. This includes, but is not limited to, damages for loss of profits, goodwill, data, or other intangible losses.
7.2. We do not guarantee the accuracy, completeness, or reliability of any information provided on our website or through our services. Your use of our website and services is at your own risk.
7.3. UKworkx shall not be liable for any damages or losses resulting from the use of third-party websites or services linked to or from our website. We do not endorse or have control over the content, products, or services provided by these third parties.
7.4. In no event shall UKworkx's total liability to you for all damages, losses, and causes of action exceed the amount paid by you, if any, for accessing or using our website or services.
7.5. Some jurisdictions do not allow the exclusion or limitation of certain types of damages, so some of the above limitations may not apply to you. In such cases, UKworkx's liability shall be limited to the fullest extent permitted by applicable law.
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">8. Intellectual Property</h2>
            <p>Intellectual Property
8.1. All content on the UKworkx website, including text, graphics, logos, images, and software, is the property of UKworkx and is protected by copyright, trademark, and other intellectual property laws.
8.2. You may not reproduce, modify, distribute, transmit, display, publish, or create derivative works based on our website content without our prior written consent. Any unauthorized use of our content may violate copyright, trademark, and other applicable laws.
8.3. UKworkx grants you a limited, non-exclusive, non-transferable, and revocable license to access and use our website and services for your personal, non-commercial use, subject to these Terms.
8.4. You acknowledge and agree that any feedback, suggestions, or ideas you provide to UKworkx regarding our website or services shall become the sole property of UKworkx. We may use and disclose such feedback for any purpose without any obligation to you.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">9. Modifications to Terms and Conditions</h2>
            <p>Modifications to Terms and Conditions
9.1. UKworkx reserves the right to modify these Terms at any time, without prior notice. Any changes to these Terms will be effective immediately upon posting the updated Terms on our website.
9.2. It is your responsibility to review these Terms periodically for any changes. Your continued use of our website and services after the posting of any changes constitutes your acceptance of those changes.
9.3. If you do not agree with any modifications to these Terms, you must immediately cease using our website and services. By continuing to use our website and services after any modifications, you agree to be bound by the updated Terms.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">10. Governing Law and Jurisdiction</h2>
            <p>Governing Law and Jurisdiction
10.1. These Terms shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law provisions.
10.2. Any disputes arising out of or relating to these Terms or your use of our website or services shall be subject to the exclusive jurisdiction of the courts of the United Kingdom.
10.3. You agree that any cause of action related to or arising out of your relationship with UKworkx must be commenced within one (1) year after the cause of action accrues. Otherwise, such cause of action shall be permanently barred.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">11. Severability</h2>
           <p>Severability
11.1. If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.
11.2. Any invalid, illegal, or unenforceable provision shall be deemed severed from these Terms to the extent of its invalidity, illegality, or unenforceability, and the remaining provisions shall be construed in a manner that most closely reflects the original intention of the parties.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">12. Waiver</h2>
            <p>Waiver
            12.1. No waiver of any provision of these Terms shall be deemed a further or continuing waiver of such provision or any other provision, and UKworkx's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">13. Entire Agreement</h2>
            <p>Entire Agreement
            13.1. These Terms, together with our Privacy Policy, constitute the entire agreement between you and UKworkx regarding your use of our website and services, and supersede all prior or contemporaneous communications and proposals, whether oral or written, between you and UKworkx.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">14. Contact Us</h2>
            <p>Contact Us
14.1. If you have any questions or concerns about these Terms or our services, please contact us at help@ukworkx.com.
14.2. We will make every effort to respond to your inquiries in a timely manner and address any issues you may have.</p>
          </section>

          <section>
            
            <p className='font-semibold py-5'>By using the UKworkx website and services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with these Terms, you must not use our website or services.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">Privacy Policy</h2>

          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">1. Introduction</h2>
            <p>Introduction
1.1. UKworkx ("we", "us", "our") is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy describes how we collect, use, and disclose your personal information when you use our website (www.ukworkx.com) and services.
1.2. By using our website and services, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy. If you do not agree with any part of this Privacy Policy, you must not use our website or services.
1.3. This Privacy Policy is incorporated into and subject to our Terms and Conditions. Any capitalized terms used but not defined in this Privacy Policy shall have the meaning given to them in our Terms and Conditions.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">2. Information We Collect</h2>
            <p>Information We Collect
2.1. Personal Information
2.1.1. When you use our services, we may collect personal information such as your name, email address, and resume. We collect this information when you voluntarily provide it to us, such as when you register for an account, purchase a plan, or contact us for support.
2.1.2. We may also collect personal information from third-party sources, such as social media platforms or publicly available databases, in accordance with the privacy policies of those sources.
2.2. Usage Data
2.2.1. We may collect information about how you use our website, including your IP address, browser type, referring/exit pages, and operating system. This information is collected automatically through the use of cookies and other tracking technologies.
2.2.2. We use this usage data to analyze trends, administer the website, track users' movements around the website, and gather demographic information for aggregate use. This data is not linked to any personally identifiable information.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">3. Use of Your Information</h2>
            <p>Use of Your Information
3.1. We use your personal information to provide and improve our services, respond to your inquiries, and communicate with you about our services. Specifically, we may use your information for the following purposes:
3.1.1. To create and manage your account, process your payments, and provide you with the services you have purchased.
3.1.2. To personalize your experience on our website and provide you with targeted career guidance and job search support based on your profile and preferences.
3.1.3. To send you administrative emails, such as confirmations, invoices, technical notices, updates, security alerts, and support and maintenance messages.
3.1.4. To respond to your comments, questions, and requests and provide customer service.
3.1.5. To monitor and analyze usage and trends, and improve the functionality and performance of our website and services.
3.2. We may use your email address to send you a one-time email regarding our consultation services. You can opt-out of receiving future emails by following the unsubscribe instructions provided in the email. We will not use your email address for any other marketing purposes without your explicit consent.
3.3. We use your uploaded resume to train our machine learning algorithms for the purpose of improving our services for other users. Your resume will be stored indefinitely on our servers for this purpose, unless you request its deletion.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">4. Disclosure of Your Information</h2>
            <p>Disclosure of Your Information
4.1. We do not sell, trade, or otherwise transfer your personal information to third parties without your explicit consent, except as described in this Privacy Policy.
4.2. We may disclose your personal information to our trusted third-party service providers who assist us in operating our website, conducting our business, or providing services to you, such as payment processors, data analyzers, and customer support providers. These third parties are obligated to maintain the confidentiality and security of your personal information and are prohibited from using it for any other purpose.
4.3. We may disclose your personal information if required to do so by law or in the good faith belief that such action is necessary to comply with legal obligations, protect and defend the rights or property of UKworkx, prevent or investigate possible wrongdoing in connection with our services, or protect the personal safety of our users or the public.
4.4. In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity. We will notify you via email and/or a prominent notice on our website of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">5. Data Security</h2>
            <p>Data Security
5.1. We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include encrypting sensitive information, restricting access to personal information to authorized personnel only, and regularly monitoring our systems for potential vulnerabilities.
5.2. However, no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
5.3. You are responsible for maintaining the confidentiality of your account login credentials and for any activity that occurs under your account. We encourage you to use strong passwords and to log out of your account when not in use.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">6. Your Rights</h2>
            <p>Your Rights
6.1. You have the right to access, correct, or delete your personal information that we hold. You may request this by contacting us at the email address provided below.
6.2. We will respond to your request within a reasonable timeframe and may ask you to verify your identity before proceeding with your request. In some cases, we may be legally required to retain certain information or may be unable to delete information that has been aggregated or anonymized.
6.3. Please note that deleting your personal information may limit our ability to provide you with our services, and we may retain certain information as necessary for our legitimate business interests or to comply with legal obligations.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">7. Cookies</h2>
            <p>Cookies
7.1. We may use cookies and similar tracking technologies to collect information about your browsing activities on our website. Cookies are small data files stored on your device that allow us to recognize you and improve your user experience.
7.2. We use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your device until you delete them). We use cookies for the following purposes:
7.2.1. To identify you as a unique user and store your preferences and settings.7.2.2. To analyze website usage and trends, and gather demographic information for aggregate use.
7.2.3. To serve targeted advertisements and personalized content based on your interests and browsing behavior.
7.3. You can adjust your browser settings to refuse cookies or alert you when cookies are being sent. However, please note that some parts of our website may not function properly if you disable cookies.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">8. Third-Party Links</h2>
            <p>Third-Party Links
8.1. Our website may contain links to third-party websites, such as social media platforms, job boards, or advertisers. We are not responsible for the privacy practices or content of those websites.
8.2. Clicking on third-party links may allow those third parties to collect or share data about you. We encourage you to read the privacy policies of any third-party websites you visit before providing them with your personal information.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">9. Children's Privacy</h2>
            <p>Children's Privacy
9.1. Our website and services are not intended for children under the age of 16. We do not knowingly collect personal information from children under 16.
9.2. If we become aware that we have collected personal information from a child under 16 without parental consent, we will take steps to delete the information as soon as possible. If you believe that we may have collected information from a child under 16, please contact us immediately.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">10. Changes to This Privacy Policy</h2>
            <p>Changes to This Privacy Policy
10.1. We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The "Last updated" date at the top of this Privacy Policy indicates when it was last revised.
10.2. We encourage you to review this Privacy Policy periodically for any changes. Your continued use of our website and services after the posting of any changes constitutes your acceptance of the revised Privacy Policy.</p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">11. Contact Us</h2>
            Contact Us
11.1. If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at [help@ukworkx.com].
11.2. We will make every effort to respond to your inquiries in a timely manner and address any privacy-related issues you may have.
          </section>
          <section>
            
            <p className='font-semibold py-5'>This Privacy Policy, together with our Terms and Conditions, constitutes the entire agreement between you and UKworkx regarding the handling of your personal information and your use of our website and services.
            By using the UKworkx website and services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy. If you do not agree with this Privacy Policy, you must not use our website or services. Last updated: 24-05-2024

            </p>
          </section>
        </div>
      </main>
    </div>
    <Footer />
    </>
  );
};

export default LegalPage;