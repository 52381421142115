import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "What is the UKworkx Job Search App?",
    answer:
      "The UKworkx Job Search App is a powerful tool designed to streamline your job search across various job boards in the UK. It consolidates job listings into one easy-to-use platform and indicates whether a position offers visa sponsorship, helping you make informed decisions quickly.",
  },
  {
    question: "How can the UK Job Search App help me find a job?",
    answer:
      "Our app simplifies your job search by aggregating job listings from multiple sources into a single, searchable database. It saves you time and effort by providing a comprehensive view of available opportunities and indicating whether positions offer visa sponsorship.",
  },

  {
    question: "How does the visa sponsorship feature work?",
    answer:
      "The UK Job Search App scans job listings for information about visa sponsorship and clearly marks each position with either 'Yes' for sponsorship available or 'Unknown' if the status is not specified. This feature allows you to focus on opportunities that match your visa requirements.",
},
  {
    question: "Is the information about visa sponsorship reliable? ",
    answer:
      "While we strive to provide the most accurate information, the sponsorship status is based on the details provided in the job listings. For the most precise and up-to-date information, we recommend verifying sponsorship details directly with the employer.",
  },
  {
    question: "Can international job seekers use this app?",
    answer:
      "Absolutely! The UK Job Search App is especially beneficial for international job seekers, as it helps identify job openings that offer visa sponsorship, making your search more targeted and efficient.",
  },
  {
    question: "How often is the job data updated?",
    answer:
      "The UK Job Search App regularly updates job listings to ensure you have access to the latest opportunities. Our system scans job boards frequently to provide the most current information available.",
  },
  {
    question: "What job boards does the app search?",
    answer:
      "Our app searches across a wide range of popular UK job boards, providing a comprehensive collection of job listings from multiple sources. This maximizes your chances of finding the perfect job.",
  },
  {
    question: "Is there a cost to use the UKworkx Job Search App?",
    answer:
      "Getting started is easy! Visit our website, sign up for an account, and start applying.",
  },
  {
    question: "Is my personal information safe with the UK Job Search App?",
    answer:
      "Yes, we prioritize your privacy and security. All personal data is protected using advanced encryption and security measures. We do not share your information with third parties without your consent. For more details, please review our privacy policy.",
  },
  {
    question: "Can I get support if I have questions or issues with the app?",
    answer:
      "Of course! Our customer support team is available to assist you with any questions or issues you may encounter. You can contact us by sending us an e-mail at help@ukworkx.com with the subject 'Application issue', and we’ll be happy to help.",
  },
]

export default function FaqSection() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
