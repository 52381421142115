import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import api from '../../utils/api';
import { useNotification } from "../../NotificationContext";
import JsonEditor from './JsonEditor';

const BACKEND_URL = 'https://jobs.ukworkx.com';

const ResumeEditor = ({ editedResume, handleChange, handleSaveEdit, setIsEditing, isSaving }) => {
  const handleSave = async () => {
    if (isSaving) return;
    handleSaveEdit();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 overflow-y-auto h-full w-full z-50">
      <div className="relative top-5 mx-auto p-5 border w-full max-w-4xl shadow-lg rounded-md bg-white">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-4">
          Edit Your CV
        </h2>
        <p className='text-red-600'>This feature is still in Beta. Please only edit the parts of your CV that are incorrect or get in touch with the team help@ukworkx.com</p>
        <div className="max-h-[70vh] overflow-y-auto pr-4">
          <JsonEditor data={editedResume} onChange={handleChange} />
        </div>
        <div className="mt-6 flex items-center justify-end space-x-4">
          <button 
            onClick={() => setIsEditing(false)} 
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={isSaving}
          >
            Cancel
          </button>
          <button 
            onClick={handleSave} 
            className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isSaving ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isSaving}
          >
            {isSaving ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              'Save Changes'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

function Profile() {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedResume, setEditedResume] = useState(null);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const fetchProfile = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
  
    try {
      const response = await api.get('/auth/profile');
      setProfile(response.data);
    } catch (error) {
      setError('Failed to load profile. Please try logging in again.');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const handleResumeUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    if (!['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type)) {
      showNotification('Please upload a PDF or DOCX file', 'error');
      return;
    }
  
    setIsUploading(true);
    const formData = new FormData();
    formData.append('resume', file);
  
    try {
      const uploadResponse = await api.post('/user/upload-resume', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      setProfile(prevProfile => ({
        ...prevProfile,
        resume: uploadResponse.data.resume
      }));
      showNotification('Resume uploaded. Please wait...', 'success');
      
      // Automatically process the resume
      setIsProcessing(true);
      const processResponse = await api.post('/user/process-resume');
      
      setProfile(prevProfile => ({
        ...prevProfile,
        aiGeneratedResume: processResponse.data.aiGeneratedResume,
        aiGeneratedResumeJSON: processResponse.data.aiGeneratedResumeJSON
      }));
  
      // Update editedResume state with the new data
      if (processResponse.data.aiGeneratedResumeJSON) {
        setEditedResume(JSON.parse(processResponse.data.aiGeneratedResumeJSON));
      } else {
        console.error('aiGeneratedResumeJSON is missing from the response');
        setEditedResume({});
      }
  
      showNotification('Resume processed successfully', 'success');
      setTimestamp(Date.now());
    } catch (error) {
      showNotification('Failed to upload or process resume. Please try again.', 'error');
      console.error('Error uploading or processing resume:', error);
    } finally {
      setIsUploading(false);
      setIsProcessing(false);
    }
  };

  const handleEditClick = () => {
    let resumeData;
    try {
      resumeData = JSON.parse(profile.aiGeneratedResumeJSON);
    } catch (error) {
      console.error("Error parsing aiGeneratedResumeJSON:", error);
      resumeData = {}; // Set a default empty object if parsing fails
    }
    setEditedResume(resumeData);
    setIsEditing(true);
  };

  const handleSaveEdit = async () => {
    try {
      setIsSaving(true);
      const response = await api.post('/user/update-resume', { resume: editedResume });
      setProfile(prevProfile => ({
        ...prevProfile,
        aiGeneratedResume: response.data.aiGeneratedResume,
        aiGeneratedResumeJSON: JSON.stringify(editedResume)  // Use editedResume directly
      }));
      setIsEditing(false);
      setTimestamp(Date.now());
      showNotification('Resume updated successfully', 'success');
      
      // Refresh the page to show updated resume
      window.location.reload();
    } catch (error) {
      showNotification('Failed to update resume. Please try again.', 'error');
      console.error('Error updating resume:', error);
    } finally {
      setIsSaving(false);
    }
  };

  
  const handleChange = useCallback((path, value) => {
    setEditedResume(prevResume => {
      const newResume = JSON.parse(JSON.stringify(prevResume));
      let current = newResume;
      for (let i = 0; i < path.length - 1; i++) {
        if (current[path[i]] === undefined) {
          if (typeof path[i + 1] === 'number' || (typeof path[i + 1] === 'string' && !isNaN(path[i + 1]))) {
            current[path[i]] = [];
          } else {
            current[path[i]] = {};
          }
        }
        current = current[path[i]];
      }
      const lastKey = path[path.length - 1];
      if (Array.isArray(current) && typeof lastKey === 'string' && !isNaN(lastKey)) {
        current[parseInt(lastKey, 10)] = value;
      } else {
        current[lastKey] = value;
      }
      return newResume;
    });
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className='pb-20'>
        <Navbar />
      </div>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-10">
        <div className="bg-white overflow-hidden shadow rounded-lg border">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              User Profile
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Your personal information and subscription status.
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Full name
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {profile.name}
                </dd>
              </div>
              <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Email address
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {profile.email}
                </dd>
              </div>
              <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Phone number
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {profile.mobileNumber}
                </dd>
              </div>
              <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Subscription status
                </dt>
                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    profile.subscriptionStatus === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                  }`}>
                    {profile.subscriptionStatus === 'active' ? 'Subscribed' : 'Not Subscribed'}
                  </span>
                </dd>
              </div>
              <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm mb-5 font-medium text-gray-500">
                  Resume
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {profile.resume && (
                    <div>
                      <p>Current resume: {profile.resume.fileName}</p>
                      <p className='mb-10'>Uploaded on: {new Date(profile.resume.uploadDate).toLocaleDateString()}</p>
                    </div>
                  )}
                  <div className="flex items-center">
                    <label className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer ${isUploading || isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}>
                      {profile.resume ? 'Replace Resume' : 'Upload Resume'}
                      <input 
                        type="file" 
                        className="hidden" 
                        onChange={handleResumeUpload} 
                        accept=".pdf,.docx" 
                        disabled={isUploading || isProcessing}
                      />
                    </label>
                    <button 
                      onClick={handleEditClick} 
                      className="ml-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                      disabled={!profile.aiGeneratedResume}
                    >
                      Edit Resume
                    </button>
                    {(isUploading || isProcessing) && (
                      <div className="ml-4 flex items-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span>{isUploading ? 'Working...' : 'Processing...'}</span>
                      </div>
                    )}
                  </div>
                </dd>
              </div>
              {profile.aiGeneratedResume && (
                <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    AI-Generated Resume
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <div className="grid grid-cols-1 gap-4">
                      {profile.aiGeneratedResume.png_images.map((imagePath, index) => (
                        <img 
                          key={`${imagePath}-${timestamp}`}
                          src={`${BACKEND_URL}${imagePath}?t=${timestamp}`}
                          alt={`Resume page ${index + 1}`} 
                          className="w-full h-auto object-contain rounded shadow-lg"
                        />
                      ))}
                    </div>
                    {profile.aiGeneratedResume.pdf_file && (
                      <a 
                        href={`${BACKEND_URL}${profile.aiGeneratedResume.pdf_file}?t=${timestamp}`}
                        download="generated_resume.pdf" 
                        className="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
                      >
                       Download AI-Generated Resume PDF
                      </a>
                    )}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
        <p className='pt-5 text-center text-sm text-gray-500'>
          Need help with cancellation or want to report a bug? Contact us via E-mail at help@ukworkx.com
        </p>
      </div>
      {isEditing && (
        <ResumeEditor
          editedResume={editedResume}
          handleChange={handleChange}
          handleSaveEdit={handleSaveEdit}
          setIsEditing={setIsEditing}
          isSaving={isSaving}
        />
      )}
      <Footer />
    </>
  );
}

export default Profile;