import { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../../images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotification } from "../../NotificationContext";
import InfoBanner from "../banners/InfoBanner"

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Sponsored Jobs Board', href: '/jobs' },
  { name: 'Job Tracker', href: '/applied-jobs' }
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const isActive = (href) => {
    return href === location.pathname;
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    showNotification('Logged out successfully', 'success');
    setMobileMenuOpen(false);
    navigate('/');
  };

  return (
    <>
    <InfoBanner />
    <header className="bg-white absolute mt-15 w-full">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">UK workx</span>
            <img className="h-12 sm:h-16 w-auto" src={logo} alt="UK workx logo" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            
            <a  key={item.name}
              href={item.href}
              className={`text-sm font-semibold leading-6 ${isActive(item.href) ? 'text-green-600' : 'text-gray-900'}`}
            >
              {item.name}
            </a>
          ))}
          {user ? (
            <>
              <a href='/profile' className="text-sm font-semibold leading-6 text-gray-900">{user.name}'s Profile</a>
              <button
                onClick={handleLogout}
                className="text-sm font-semibold leading-6 text-gray-900 hover:text-green-600"
              >
                Logout
              </button>
            </>
          ) : (
            
             <a href="/login"
              className={`text-sm font-semibold leading-6 ${isActive('/login') ? 'text-green-600' : 'text-gray-900'}`}
            >
              Login
            </a>
          )}
        </div>
      </nav>
      <Transition show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="lg:hidden" onClose={() => setMobileMenuOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-10 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5 pt-20">
                  <span className="sr-only">Your Company</span>
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-15 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      
                      <a  key={item.name}
                        href={item.href}
                        className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 ${
                          isActive(item.href) ? 'bg-gray-50 text-green-600' : 'text-gray-900'
                        } hover:bg-gray-50`}
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        {item.name}
                      </a>
                    ))}
                    {user ? (
                      <>
                        <a 
                          href='/profile' 
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          {user.name}'s Profile
                        </a>
                        <button
                          onClick={() => {
                            handleLogout();
                            setMobileMenuOpen(false);
                          }}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-green-600 w-full text-left"
                        >
                          Logout
                        </button>
                      </>
                    ) : (
                      
                      <a  href="/login"
                        className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 ${
                          isActive('/login') ? 'bg-gray-50 text-green-600' : 'text-gray-900'
                        } hover:bg-gray-50`}
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        Login
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </header>
  </>
  );
}