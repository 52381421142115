import React, { useState } from 'react';
import { useNotification } from "../../NotificationContext"
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import api from '../../utils/api';


function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.post('/auth/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      const data = await response.json();
      if (response.ok) {
        setMessage('Password reset email sent. Please check your inbox.');
        showNotification('Password reset email sent.', 'success');
      } else {
        setMessage(data.error || 'An error occurred. Please try again.');
        showNotification('An error occurred', 'error');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      showNotification('An error occurred', 'error');
    }finally {
        setIsLoading(false);
      }
  };

  return (
    <>
    <div className='pb-20'>
        <Navbar />
    </div>
    <div className="max-w-md mt-8 mx-5 pt-20">
      <h2 className="text-2xl font-bold mb-4">Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <button 
        type="submit" 
        disabled={isLoading}
        className="w-full bg-blue-500 text-white py-2 rounded flex items-center justify-center"
      >
        {isLoading ? (
          <>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Loading...
          </>
        ) : 'Reset Password'}
      </button>
      </form>
      {message && <p className="mt-4 text-center">{message}</p>}
    </div>
    <Footer />
    </>
  );
}

export default ForgotPassword;