import React, { useEffect, useState } from 'react';
import { useNotification } from '../../NotificationContext';

const Notification = () => {
  const { notification } = useNotification();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (notification) {
      setIsVisible(true);
      const timer = setTimeout(() => setIsVisible(false), 2700);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  if (!notification) return null;

  return (
    <div className="fixed inset-x-0 top-5 flex justify-center items-center z-50">
      <div className={`p-4 rounded-md shadow-lg ${
        isVisible ? 'animate-slideIn' : 'animate-slideOut'
      } ${
        notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
      } max-w-sm w-full mx-4`}>
        <div className="flex items-center justify-center text-white">
          <div className="mr-3 text-xl">
            {notification.type === 'success' ? '✅' : '❌'}
          </div>
          <p className="font-bold">{notification.message}</p>
        </div>
      </div>
    </div>
  );
};

export default Notification;