import React from "react";
import { StarIcon } from "@heroicons/react/20/solid";
import facePhoto from "../../images/faces.png";

function TimelineFeature() {
  return (
    <div>
      <div className="">
        <h1 className="sm:text-3xl text-xl my-5 font-black text-center mx-2">
          Our job search tool is fast, easy to use and searches over all the
          major job boards and company career pages for visa sponsored jobs.*
        </h1>
        <ul className="timeline timeline-vertical mr-10 lg:timeline-horizontal flex justify-center">
          <li>
            <div className="timeline-start">Step 1</div>
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-end timeline-box">Enter a job title</div>
            <hr />
          </li>
          <li>
            <hr />
            <div className="timeline-start">Step 2</div>
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-end timeline-box">Click on generate documents</div>
            <hr />
          </li>

          <li>
            <hr />
            <div className="timeline-start">Step 3</div>
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-end timeline-box">Download our auto generated, ATS friendly CV and Cover Letter and apply for the job</div>
          </li>
        </ul>
      </div>
      {/* <div className="mt-5 flex justify-center">
        <a
          type="button"
          href="/jobs"
          className="cursor-pointer text-white bg-gradient-to-br from-indigo-700 to-indigo-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-12 py-2.5 text-center me-2 "
        >
          Try now - For Free! ✨
        </a>
      </div> */}
      {/* <p className="text-gray-900 text-center text-[10px] mb-5">
        No credit card/No sign-up required for your first search.
      </p>
      <div className="flex justify-center space-x-4">
        <img
          src={facePhoto}
          alt="Faces"
          className="w-40 h-8 rounded-full object-cover"
        />
        <div className="flex flex-col items-start mb-5">
          <div className="flex space-x-1">
            {[...Array(5)].map((_, index) => (
              <StarIcon
                key={index}
                className="w-3 h-3 sm:w-4 sm:h-4 text-yellow-500"
              />
            ))}
          </div>
          <span className=" mb-10 text-gray-600 text-[8px] sm:text-sm">
            Suggested by international students like you!
          </span>
        </div>
      </div> */}
    </div>
  );
}

export default TimelineFeature;
