import React, { useState, useEffect } from 'react';
import { useNotification } from "../../NotificationContext";
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import api from '../../utils/api';

const columnsOrder = ['applied', 'shortlisted', 'interviewing', 'rejected'];
const columns = {
  applied: { title: 'Applied Jobs', color: 'bg-blue-100', key: 'appliedJobs' },
  shortlisted: { title: 'Shortlisted', color: 'bg-green-100', key: 'shortlistedJobs' },
  interviewing: { title: 'Interviewing', color: 'bg-yellow-100', key: 'interviewingJobs' },
  rejected: { title: 'Rejected', color: 'bg-red-100', key: 'rejectedJobs' },
};

function JobTracker() {
  const [jobState, setJobState] = useState({
    appliedJobs: [],
    shortlistedJobs: [],
    interviewingJobs: [],
    rejectedJobs: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(null);
  const { showNotification } = useNotification();
  const [visibleJobs, setVisibleJobs] = useState({
    appliedJobs: 10,
    shortlistedJobs: 10,
    interviewingJobs: 10,
    rejectedJobs: 10,
  });

  useEffect(() => {
    fetchAllJobStages();
  }, []);

  const fetchAllJobStages = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        showNotification('Please log in to view jobs', 'error');
        return;
      }

      const response = await api.get("/user/job-stages", {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      // Sort jobs by date (newest to oldest) for each stage
      const sortedJobs = Object.keys(response.data).reduce((acc, key) => {
        acc[key] = response.data[key].sort((a, b) => {
          const dateA = new Date(a[`${key.slice(0, -4)}_at`]);
          const dateB = new Date(b[`${key.slice(0, -4)}_at`]);
          return dateB - dateA;
        });
        return acc;
      }, {});

      setJobState(sortedJobs);
    } catch (error) {
      console.error("Error fetching job stages:", error);
      showNotification('Error fetching job stages', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const moveJob = async (jobId, fromColumn, toColumn) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        showNotification('Please log in to move jobs', 'error');
        return;
      }

      await api.post("/jobs/move", {
        jobId,
        fromStage: columns[fromColumn].key,
        toStage: columns[toColumn].key
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setJobState(prevState => {
        const fromKey = columns[fromColumn].key;
        const toKey = columns[toColumn].key;
        const job = prevState[fromKey].find(j => j.job_id === jobId);
        if (!job) return prevState;
        
        const updatedToJobs = [...prevState[toKey], { ...job, [`${toColumn}_at`]: new Date() }]
          .sort((a, b) => new Date(b[`${toColumn}_at`]) - new Date(a[`${toColumn}_at`]));

        return {
          ...prevState,
          [fromKey]: prevState[fromKey].filter(j => j.job_id !== jobId),
          [toKey]: updatedToJobs,
        };
      });

      showNotification('Job moved successfully', 'success');
    } catch (error) {
      console.error("Error moving job:", error);
      showNotification('Error moving job', 'error');
    }
  };

  const removeJob = async (jobId, fromColumn) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        showNotification('Please log in to remove jobs', 'error');
        return;
      }
  
      await api.post("/jobs/remove", {
        jobId,
        stage: columns[fromColumn].key
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      setJobState(prevState => ({
        ...prevState,
        [columns[fromColumn].key]: prevState[columns[fromColumn].key].filter(job => job.job_id !== jobId)
      }));
  
      showNotification('Job removed successfully', 'success');
    } catch (error) {
      console.error("Error removing job:", error);
      showNotification('Error removing job', 'error');
    }
  };

  const loadMore = (columnKey) => {
    setVisibleJobs(prev => ({
      ...prev,
      [columnKey]: prev[columnKey] + 10
    }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Date not available';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <>
      <div className='pb-20'>
        <Navbar />
      </div>
      <div className="container mx-auto px-4 pt-10 pb-8">
        <h1 className="text-center text-2xl font-extrabold mb-8 sm:text-4xl"> <span className='font-medium'>Welcome to the </span>Job Tracker</h1>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {columnsOrder.map((columnId) => (
              <div key={columnId} className={`${columns[columnId].color} p-4 rounded-lg min-h-[200px]`}>
                <h2 className="text-xl text-center font-bold mb-4">{columns[columnId].title}</h2>
                {jobState[columns[columnId].key].slice(0, visibleJobs[columns[columnId].key]).map((job) => (
                  <div key={job.job_id} className="bg-white p-4 mb-4 rounded-lg shadow-md relative">
                    <div className="absolute top-2 right-2">
                      <button 
                        className="text-gray-500 hover:text-gray-700" 
                        onClick={() => setOpenMenu(openMenu === job.job_id ? null : job.job_id)}
                      >
                        •••
                      </button>
                      {openMenu === job.job_id && (
                        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            {columnsOrder.filter(colId => colId !== columnId).map(colId => (
                              <button
                                key={colId}
                                onClick={() => {
                                  moveJob(job.job_id, columnId, colId);
                                  setOpenMenu(null);
                                }}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                role="menuitem"
                              >
                                Move to {columns[colId].title}
                              </button>
                            ))}
                            <button
                              onClick={() => {
                                removeJob(job.job_id, columnId);
                                setOpenMenu(null);
                              }}
                              className="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 hover:text-red-900 w-full text-left"
                              role="menuitem"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <h3 className="text-lg font-semibold pr-8">{job.job_title}</h3>
                    <p className="text-sm text-gray-600">{job.employer_name}</p>
                    <p className="text-sm text-gray-500">
                      {columnId} on: {formatDate(job[`${columnId}_at`])}
                    </p>
                    <p className="mt-2 text-sm">{job.job_description?.substring(0, 100)}...</p>
                    <a 
                      href={job.job_apply_link} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="mt-2 inline-block bg-blue-500 text-white px-5 py-2 rounded text-sm"
                    >
                      View Job
                    </a>
                  </div>
                ))}
                {jobState[columns[columnId].key].length > visibleJobs[columns[columnId].key] && (
                  <button
                    onClick={() => loadMore(columns[columnId].key)}
                    className="w-full bg-white text-blue-500 px-4 py-2 rounded-lg shadow-md hover:bg-blue-50"
                  >
                    View More
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default JobTracker;